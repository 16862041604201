import * as React from "react";

import { DialogProps } from "@mui/material/Dialog";

export type DialogType =
| "About"
| "ArmMoving"
| "BallBinRefill"
| "Calibrating"
| "Cleanup"
| "ClearObstructions"
| "ContactSupport"
| "EmptyBallBin"
| "EStop"
| "FatalError"
| "Finished"
| "RefillBallBin"
| "ManualRefillBallBin"
| "BallJam"
| "BallJamNoPowerDrop"
| "Device"
| "EnteringLowPowerMode"
| "HardwareFailure"
| "HardwareShutdown"
| "InsertNewBatteries"
| "PowerDown"
| "ReplaceBatteries"
| "RemovedBattery"
| "ServiceUnavailable"
| "Shutdown"
| "NoConfirmShutdown"
| "ShutdownCleanupChecklist"
| "ShutdownHardwareChecklist"
| "ShutdownFeedback"
| "DisconnectCleanupChecklist"
| "DisconnectFeedback"
| "Unpair"
| "NoConfirmUnpair"
| "Update"
| "ForceUpdate"
| "UserUpdate"
| "NeedHelp"
| "WifiCredentials"
| "VisionFaultDialog"
| "VisionFaultServeAndVolleyDialog"
| "InvalidSportSelection"
| "SportSelectorWelcome"
| "LowBatteryWarning"
| "NoConfirmReplaceBatteries";

export interface ContextValue {
    dialogType: DialogType | null;
    forceDeviceDialog: boolean;
    ignoreProximity: boolean;
    batteryWarningDismissed: boolean;
    onClose: () => void;
    setDisableOnClose: (_: boolean) => void;
    setDialogProps: (props: Omit<DialogProps, "open">) => void;
    setDialogType: (type: DialogType | null) => void;
    setDrawerHeight: (height: string) => void;
    setIgnoreProximity: (ignore: boolean) => void;
    setBatteryWarningDismissed: (dismissed: boolean) => void;
}

export default React.createContext<ContextValue>({
    dialogType: null,
    forceDeviceDialog: false,
    ignoreProximity: false,
    batteryWarningDismissed: false,
    onClose: () => {},
    setDisableOnClose: () => {},
    setDialogProps: () => {},
    setDialogType: () => {},
    setDrawerHeight: () => {},
    setIgnoreProximity: () => {},
    setBatteryWarningDismissed: () => {},
});
