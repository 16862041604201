import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import cdnUrl from "./cdnUrl";

interface MarketingGridItem {
    iconUrl: string;
    iconAlt: string;
}

const ITEMS: MarketingGridItem[] = [
    {
        iconUrl: cdnUrl("campaigns/icons/train-on-your-schedule.png"),
        iconAlt: "stopwatch",
    },
    {
        iconUrl: cdnUrl("campaigns/icons/supercharge-your-pro.png"),
        iconAlt: "tennis player",
    },
    {
        iconUrl: cdnUrl("campaigns/icons/cutting-edge-tech.png"),
        iconAlt: "perspective platform tennis court",
    },
    {
        iconUrl: cdnUrl("campaigns/icons/workouts-on-demand.png"),
        iconAlt: "hand touching",
    },
    {
        iconUrl: cdnUrl("campaigns/icons/quick-setup.png"),
        iconAlt: "rocket ship",
    },
    {
        iconUrl: cdnUrl("campaigns/icons/easy-to-use.png"),
        iconAlt: "holding mobile device",
    },
];

export default function CampaignClubMarketingGrid(): JSX.Element {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <>
            <Typography
                align="center"
                variant={isMobile ? "h3" : "h2"}
                color="#08307f"
                sx={{ textTransform: "none" }}
            >
                Why Does Volley Make Sense For You?
            </Typography>
            <Grid container>
                {ITEMS.map(({ iconUrl, iconAlt }) => (
                    <Grid item xs={6} md={4} pb={4} key={iconUrl}>
                        <Box component="div" display="flex" justifyContent="center">
                            <img src={iconUrl} alt={iconAlt} height={isMobile ? 120 : 200} />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
